<template>
  <section>
    <PrismicRichText :field="slice.primary.text" class="heading-text" />
  </section>
</template>

<script>
import { getSliceComponentProps } from "@prismicio/vue/components";

export default {
  // The array passed to `getSliceComponentProps` is purely optional.
  // Consider it as a visual hint for you when templating your slice.
  props: getSliceComponentProps(["slice", "index", "slices", "context"]),
};
</script>
<style lang="scss">
.heading-text {
  max-width: calc($large - 12rem);
  margin: 2rem auto;
  padding: 0 min(2rem, 2vmin) 1rem;
  color: $dark-gray;

  @include for-desktop() {
    max-width: calc($large);
    margin: 4rem auto;
    padding: 0;
  }
}
.heading-text > * {
  font-family: $font-primary !important;
}
</style>
